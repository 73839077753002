import React from 'react'
import I18n from '../../helpers/I18n'
import DesignViewerDesignBom from './DesignViewerDesignBom'
import classNames from 'classnames'
import SignInProvider from '../../components/common/providers/SignInProvider'
import DownloadBomButton from '../../partials/common/DownloadBomButton'
import { MachineLogicProgram, Part } from '../../api/Parts/Types'
import { PartnerPart } from '../../api/Common/Types'
import { User } from '../../api/Users/Types'
import { DeploymentBundle } from '../../components/designs/bom/DesignBomDeploymentBundle'

interface BomPropsPart {
  parts: ReadonlyArray<Part>
  partsType: string
  partner_parts: ReadonlyArray<PartnerPart>
  className: string
  expanded: string
}

interface CurrentUserForReact extends Omit<User, 'activated'> {
  vention_can_admin: boolean
  done_onboarding_tutorial: boolean
  admin_on: boolean
  visitor: boolean
  activated: boolean
  first_time_log_in: boolean
  show_referral_ad: boolean
  guest_checkout_user: boolean
}

export interface BomProps {
  vention_parts: BomPropsPart
  partner_parts: BomPropsPart
  machine_logic_programs: ReadonlyArray<MachineLogicProgram>
  show_partner_parts: boolean
  deployment_bundle: DeploymentBundle
  design_id: number
  currentUser: CurrentUserForReact
}

const DesignViewerBom: React.FC<BomProps> = ({
  vention_parts,
  partner_parts,
  machine_logic_programs,
  show_partner_parts,
  deployment_bundle,
  design_id,
  currentUser,
}) => {
  return (
    <SignInProvider currentUser={currentUser as any}>
      <div className='design-viewer-bom'>
        <div className='design-viewer-bom-section'>
          <div className='design-viewer-bom-title-container'>
            <h2
              className={classNames(
                'design-viewer-bom-title',
                'section-bill_of_material',
                'take-rate-partner-parts-list'
              )}
            >
              {I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.bill_of_material')}
            </h2>
            <DownloadBomButton
              buttonText={I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.download_bom')}
              design_id={design_id}
              new_parts={vention_parts.parts as any}
              partner_parts={partner_parts.partner_parts as any}
            />
          </div>

          <DesignViewerDesignBom {...vention_parts} {...machine_logic_programs} deployment_bundle={deployment_bundle} />
        </div>
        {show_partner_parts && (
          <div className='design-viewer-bom-section'>
            <h2
              className={classNames(
                'design-viewer-bom-title',
                'section-bill_of_material',
                'free-paid-partner-parts-list'
              )}
            >
              Partner Models (Not included)
            </h2>
            <DesignViewerDesignBom {...partner_parts} />
          </div>
        )}
      </div>
    </SignInProvider>
  )
}

export default DesignViewerBom
