import React, { useEffect, useState } from 'react'
import UserService from '../../../api/Users/Service'

interface Props {
  image_path: string
  width: string
  height: string
  length: string
  part_number: string
  name: string
  id: string
  quote_request_supported: string
  appears_in_bom_models: string
  partner_name: string
  is_published: boolean
  public_partner_page_url: string | null
  display_as_being_sold_by_vention: boolean
  status: string
}

const DesignBomPartnerPart: React.FunctionComponent<Props> = ({
  image_path,
  width,
  height,
  length,
  part_number,
  name,
  id,
  quote_request_supported,
  appears_in_bom_models,
  partner_name,
  is_published,
  public_partner_page_url,
  display_as_being_sold_by_vention,
  status,
}) => {
  return (
    <>
      <div className='design-bom-item free-paid-partner-part'>
        <div className='design-bom-item-description'>
          <img src={image_path} alt={name} />
          <div className='design-bom-item-details'>
            <div className='design-bom-item-link'>
              {is_published ? (
                <a href={`/partners/parts/${id}/${part_number}`}>
                  <b>{name}</b>
                </a>
              ) : (
                <b>{name}</b>
              )}
            </div>
            <div className='design-bom-item-info'>
              {parseFloat(width).toFixed(0)}&nbsp;x&nbsp;
              {parseFloat(height).toFixed(0)}&nbsp;x&nbsp;
              {parseFloat(length).toFixed(0)} mm
            </div>
            <div className='design-bom-item-info'>{part_number}</div>
          </div>
        </div>
        <div className='design-bom-item-total'>
          {quote_request_supported && (
            <a
              className='button button-outline button-block'
              href={`/partners/parts/${id}/quotes/new`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Get a quote
            </a>
          )}
          {appears_in_bom_models && (
            <div>
              {status === 'deprecated' ? (
                <div className='design-bom-partner-part-obsolete-status'>Obsolete</div>
              ) : display_as_being_sold_by_vention ? (
                <>supplied by Vention</>
              ) : (
                <>
                  Supplied by{' '}
                  {public_partner_page_url ? (
                    <a className='supplied-by-link' href={public_partner_page_url}>
                      {partner_name}
                    </a>
                  ) : (
                    <span className='supplied-by-link'>{partner_name}</span>
                  )}{' '}
                  and subject to their terms & conditions
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DesignBomPartnerPart
