import React from 'react'
import I18n from '../../helpers/I18n'
import DesignBom from '../../components/designs/DesignBom'
import PartnerModels from '../../components/designs/PartnerModels'
import { scrollToClass } from '../../helpers/LegacyJquery'

class DesignViewerDesignBom extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: 'half' }
    this.toggleExpand = this.toggleExpand.bind(this)
  }
  toggleExpand() {
    const { className } = this.props
    if (this.state.expanded) {
      scrollToClass(`${className}.section-bill_of_material`)
    }
    this.setState({
      expanded: this.state.expanded === 'half' ? 'full' : 'half',
    })
  }

  render() {
    const { parts, partner_parts, partsType, machine_logic_programs, deployment_bundle } = this.props
    return (
      <div>
        {partsType === 'purchaseableParts' ? (
          <DesignBom
            parts={parts}
            partner_parts={partner_parts}
            machine_logic_programs={machine_logic_programs}
            expanded={this.state.expanded}
            deployment_bundle={deployment_bundle}
          />
        ) : partsType === 'partnerModels' ? (
          <PartnerModels
            parts={parts}
            partner_parts={partner_parts}
            expanded={this.state.expanded}
          />
        ) : null}
        {parts.length + partner_parts.length > 4 && (
          <div
            className='text-center link-underline-light design-viewer-design-bom-load-button'
            onClick={this.toggleExpand}
          >
            {this.state.expanded === 'half' ? I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.load_more_rows') : I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.load_less')}
          </div>
        )}
      </div>
    )
  }
}
export default DesignViewerDesignBom
