import React from 'react'
import PartnerModelsPart from './bom/PartnerModelsPart'

class PartnerModels extends React.Component {
  constructor(props) {
    super(props)
    this.displayName = 'DesignBom'
    this.fetchSignedUrl = this.fetchSignedUrl.bind(this)
    this.ajaxService = AjaxService().getInstance()
  }
  showPart(part) {
    if (part.instance_parameters != null && part.instance_parameters.imported_part_number != null) {
      toastr.warning(
        'Custom imported parts do not have parts pages, for details please contact Vention Support',
        {
          timeOut: 5000,
        }
      )
    } else {
      window.location.href = `/parts/${part.url}-${part.id}`
    }
  }
  fetchSignedUrl(partKey) {
    return () => {
      this.ajaxService.getSignedAwsUrl(partKey, res => {
        window.open(res['url'])
      })
    }
  }

  partnerPartRows() {
    const { partner_parts } = this.props
    return partner_parts.map((part, i) => <PartnerModelsPart {...part} key={`partner-part-${i}`} />)
  }

  render() {
    const { expanded, checkout, parts, partner_parts } = this.props
    return (
      <div
        className='design-bom'
        style={{
          borderBottom: expanded !== 'empty' ? '2px solid #EFF2F7' : 'none',
          height:
            expanded === 'full'
              ? 'auto'
              : expanded === 'half'
              ? parts.length + partner_parts.length >= 5
                ? '400px'
                : 'auto'
              : '0',
          overflow: checkout ? 'hidden' : 'auto',
          background: 'white',
        }}
      >
        <div className='design-bom-labels free-paid-partner-part-labels hidden-sm hidden-xs'>
          <div className='design-bom-label-description'>Item description</div>
          <div className='design-bom-label'>Supplied by</div>
        </div>
        {this.partnerPartRows()}
      </div>
    )
  }
}
export default PartnerModels
