import React from 'react'
import ReactTooltip from 'react-tooltip'
import Badge from '../../common/display/Badge'
import HintPopover from '../../common/HintPopover'

interface Props {
  name: string
}

const DesignBomMachineLogicProgram: React.FunctionComponent<Props> = ({ name }) => {
  return (
    <div className='design-bom-item'>
      <div className='design-bom-item-description'>
        <img
          src={'https://s3.amazonaws.com/ventionblobs/parts/png555/CE-SW-001-0003.png'}
          alt={name}
          loading='lazy'
        />
        <div>
          <div className='design-bom-item-link'>
            <b>{name}</b>
          </div>
          <ReactTooltip
            id='machine-logic-program-tooltip'
            place='top'
            delayHide={200}
            effect='solid'
            className='persistent-tooltip-on-hover'
          >
            <div className='design-bom-tooltip'>
              <p>
                A MachineLogic program contains your custom code-free program that allows you to
                deploy onto a MachineMotion controller.
              </p>
              For specific information on Machine Logic Programs, please reach out to{' '}
              <a href='mailto:customersuccess@vention.cc'>customersuccess@vention.cc</a>.
            </div>
          </ReactTooltip>
          <div data-tip data-for='machine-logic-program-tooltip' style={{ width: 'min-content' }}>
            <Badge>Download Available</Badge>
          </div>
        </div>
      </div>
      <div className='design-bom-item-status'>
        <div>Download</div>
        <HintPopover
          hintText={'After purchase, to access your MachineLogic Program download, open your design in MachineBuilder, and download in the MachineLogic tab'}
          size='large'
        />
      </div>
      <div className='design-bom-item-quantity'>1</div>
    </div>
  )
}

export default DesignBomMachineLogicProgram
