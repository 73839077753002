import React from 'react'
import I18n from '../../helpers/I18n'
import VentionPartOptions from '../parts/VentionPartOptions'
import DesignBomPartnerPart from './bom/DesignBomPartnerPart'
import { getLeadTimeText, getPartVersionAndNumberString } from '../../helpers/Parts'
import DesignBomMachineLogicProgram from './bom/DesignBomMachineLogicProgram'
import { DesignBomDeploymentBundle } from './bom/DesignBomDeploymentBundle'
import { buildDimensions } from '../../helpers/UnitsOfMeasure'
import Badge from '../common/display/Badge'
import ReactTooltip from 'react-tooltip'

const itemStatusColor = status => {
  switch (status) {
    case 'in_stock':
    case 'deplete':
      return 'initial'
    case 'soon':
      return '#197ce0'
    default:
      return 'red'
  }
}

export const shipmentDelayText = shipment_delay => {
  if (shipment_delay) {
    if (shipment_delay === 0) {
      return 'Ships next day'
    } else if (shipment_delay <= 18) {
      return `Ships in ${shipment_delay} days`
    } else {
      return `Ships in ${Math.round(shipment_delay / 7)} weeks`
    }
  }
}

class DesignBom extends React.Component {
  constructor(props) {
    super(props)
    this.displayName = 'DesignBom'
    this.fetchSignedUrl = this.fetchSignedUrl.bind(this)
    this.partRows = this.partRows.bind(this)
    this.ajaxService = AjaxService().getInstance()
  }

  noCustomPartsPageWarning() {
    toastr.warning(
      'Custom imported parts do not have parts pages, for details please contact Vention Support',
      {
        timeOut: 5000,
      }
    )
  }

  customPart(part) {
    return part.instance_parameters != null && part.instance_parameters.imported_part_number != null
  }

  fetchSignedUrl(partKey) {
    return () => {
      this.ajaxService.getSignedAwsUrl(partKey, res => {
        window.open(res['url'])
      })
    }
  }

  // Shipment delay stored on instance params
  hasCustomShipmentDelay(part) {
    return (
      part.instance_parameters && part.instance_parameters.hasOwnProperty('custom_shipping_delay')
    )
  }

  partStatus = part => {
    const shipping_delay = this.hasCustomShipmentDelay(part)
      ? part.instance_parameters.custom_shipping_delay
      : part.shipment_delay
    if (part.status !== 'in_stock') {
      return VentionPartOptions.availability[part.status]
    } else if (shipping_delay === 0) {
      return VentionPartOptions.availability[part.status]
    } else if (shipping_delay === 1) {
      return 'Ships in 1 day'
    } else {
      return `Ships in ${getLeadTimeText(shipping_delay)}`
    }
  }

  partRows() {
    const { parts, checkout } = this.props
    const partsToShow = parts.filter(part => !part.hide_in_bom)

    return partsToShow.map((part, i) => {
      const partWidth =
        part.hasOwnProperty('instance_parameters') &&
        part.instance_parameters.hasOwnProperty('custom_width')
          ? part.instance_parameters.custom_width
          : part.width
      const partHeight =
        part.hasOwnProperty('instance_parameters') &&
        part.instance_parameters.hasOwnProperty('custom_height')
          ? part.instance_parameters.custom_height
          : part.height
      const partLength =
        part.hasOwnProperty('instance_parameters') &&
        part.instance_parameters.hasOwnProperty('custom_length')
          ? part.instance_parameters.custom_length
          : part.length
      const partArea =
        part.hasOwnProperty('instance_parameters') &&
        part.instance_parameters.hasOwnProperty('area_in_m2')
          ? part.instance_parameters.area_in_m2
          : null

      const PartDimensions = () => {
        return partArea ? (
          <>
            {parseFloat(partArea).toFixed(2)} m<sup>2</sup>
          </>
        ) : (
          <>
            {buildDimensions({
              length: Number(parseFloat(partLength).toFixed(0)),
              width: Number(parseFloat(partWidth).toFixed(0)),
              height: Number(parseFloat(partHeight).toFixed(0)),
            })}
          </>
        )
      }
      const PartName = () => {
        return this.customPart(part) ? (
          <button
            className='design-bom-item-button'
            onClick={() => this.noCustomPartsPageWarning()}
          >
            <b>{part.name}</b>
          </button>
        ) : (
          <a href={`/parts/${part.url}-${part.id}`}>
            <b>{part.name}</b>
          </a>
        )
      }

      return (
        <div key={`part-${i}`} className='design-bom-item'>
          <div className='design-bom-item-description'>
            <img src={part.thumbnail_image_path} alt={part.name} loading='lazy' />
            <div>
              <div className='design-bom-item-link'>
                {PartName(part)}
                <div>{part.is_imported ? ' (IMPORTED PART)' : ''}</div>
              </div>
              <div className='design-bom-item-info'>{checkout ? null : <PartDimensions />}</div>
              <div className='design-bom-item-info'>
                {part.hasOwnProperty('instance_parameters') &&
                part.instance_parameters.hasOwnProperty('imported_part_number') ? (
                  <>{part.instance_parameters.imported_part_number}</>
                ) : (
                  <>{getPartVersionAndNumberString(part.part_number)}</>
                )}
                {part.is_custom && (
                  <>
                    <ReactTooltip
                      id='custom-part'
                      place='top'
                      delayHide={200}
                      effect='solid'
                      className='persistent-tooltip-on-hover'
                    >
                      <div className='design-bom-tooltip'>
                        Custom parts are not standard to our parts library and are specially
                        acquired for your design. If you cancel an order containing custom parts
                        later than 24 hours after your purchase, you will be charged the full price
                        of the custom parts (Shipping and Taxes will not be included). If you have
                        any questions please contact us at{' '}
                        <a href='mailto:sales@vention.io'>sales@vention.io</a>.
                      </div>
                    </ReactTooltip>
                    <div data-tip data-for='custom-part' style={{ width: 'min-content' }}>
                      <Badge>Custom</Badge>
                    </div>
                  </>
                )}
              </div>
              <div className='design-bom-item-info'>
                {part.hasOwnProperty('instance_parameters') &&
                part.instance_parameters.hasOwnProperty('aws_3dmodel') ? (
                  <button
                    className='button-link-underline no-padding'
                    onClick={this.fetchSignedUrl(part.instance_parameters.aws_3dmodel)}
                  >
                    Customized 3D Model
                  </button>
                ) : null}
              </div>
              {!!part.shipping_warning && (
                <div className='item-shipping-warning'>{part.shipping_warning}</div>
              )}
            </div>
          </div>
          <div className='design-bom-item-status' style={{ color: itemStatusColor(part.status) }}>
            {this.partStatus(part)}
          </div>
          <div className='design-bom-item-quantity'>{part.quantity}</div>
        </div>
      )
    })
  }

  partnerPartRows() {
    const { partner_parts } = this.props
    return partner_parts.map((part, i) => (
      <DesignBomPartnerPart {...part} key={`partner-part-${i}`} />
    ))
  }

  deploymentBundleRow() {
    if (this.props.deployment_bundle) {
      return <DesignBomDeploymentBundle {...this.props.deployment_bundle} />
    } else {
      return null
    }
  }

  machineLogicProgramRows() {
    const { machine_logic_programs } = this.props
    if (machine_logic_programs) {
      // incase this component is used elsewhere without machine logic programs
      return machine_logic_programs.map((machine_logic_program, i) => {
        return <DesignBomMachineLogicProgram name={machine_logic_program.name} key={i} />
      })
    }
  }

  render() {
    const { expanded, checkout, parts, partner_parts, checkoutBom } = this.props
    return (
      <div
        className='design-bom'
        style={{
          borderBottom: expanded !== 'empty' ? '2px solid #EFF2F7' : 'none',
          height:
            expanded === 'full'
              ? 'auto'
              : expanded === 'half'
              ? parts.length + partner_parts.length >= 5
                ? '400px'
                : 'auto'
              : '0',
          overflow: checkout ? 'hidden' : 'auto',
          background: 'white',
        }}
      >
        {!checkoutBom && (
          <div className='design-bom-labels hidden-sm hidden-xs'>
            <div className='design-bom-label-description'>
              {I18n.t(
                'frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.item_description'
              )}
            </div>
            <div className='design-bom-label'>
              {I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.status')}
            </div>
            <div className='design-bom-label'>
              {I18n.t('frontend.pages.designs.design_viewer_tabs.bill_of_material_tab.quantity')}
            </div>
          </div>
        )}
        {this.deploymentBundleRow()}
        {this.machineLogicProgramRows()}
        {this.partRows()}
        {this.partnerPartRows()}
      </div>
    )
  }
}
export default DesignBom
