import React from 'react'
import { buildDimensions } from '../../../helpers/UnitsOfMeasure'
import ReactTooltip from 'react-tooltip'
import Badge from '../../common/display/Badge'
import { shipmentDelayText } from '../DesignBom'

interface Props {
  readonly image_path: string
  readonly width: string
  readonly height: string
  readonly length: string
  readonly part_number: string
  readonly name: string
  readonly id: string
  readonly partner_feature_level: string
  readonly partner_name: string
  readonly supplier_price_usd: string
  readonly quantity: number
  readonly currency_code: string
  readonly currency_symbol: string
  readonly display_as_being_sold_by_vention: boolean
  readonly supplier_shipment_delay?: number
  shipping_warning?: string
}

const DesignBomPartnerPart: React.FunctionComponent<Props> = ({
  image_path,
  width,
  height,
  length,
  part_number,
  name,
  id,
  partner_feature_level,
  partner_name,
  quantity,
  shipping_warning,
  display_as_being_sold_by_vention,
  supplier_shipment_delay,
}) => {
  return (
    <>
      <div className='design-bom-item'>
        <div className='design-bom-item-description'>
          <img src={image_path} alt={name} loading='lazy' />
          <div className='design-bom-item-details'>
            <div className='design-bom-item-link'>
              <a href={`/partners/parts/${id}/${part_number}`}>
                <b>{name}</b>
              </a>
            </div>
            <div className='design-bom-item-info'>
              {buildDimensions({
                length: Number(parseFloat(length).toFixed(0)),
                width: Number(parseFloat(width).toFixed(0)),
                height: Number(parseFloat(height).toFixed(0)),
              })}
            </div>
            <div className='design-bom-item-info'>
              {part_number}
              <ReactTooltip
                id='partner-part-tooltip'
                place='top'
                delayHide={200}
                effect='solid'
                className='persistent-tooltip-on-hover'
              >
                <div className='design-bom-tooltip'>
                  Partner parts are supplied by a Vention Partner and are subject to that Partner’s
                  terms & conditions, including their own warranty and return policies. Shipping and
                  return fees may apply.
                  <br />
                  <br />
                  For specific information on applicable information for Partner Parts, please reach
                  out to <a href='mailto:partners@vention.io'>partners@vention.io</a>.
                </div>
              </ReactTooltip>
              <div data-tip data-for='partner-part-tooltip' style={{ width: 'min-content' }}>
                <Badge>Partner Part</Badge>
              </div>
              {!!shipping_warning && (
                <div className='item-shipping-warning'>{shipping_warning}</div>
              )}
            </div>
          </div>
        </div>
        <div className='design-bom-item-status'>
          {display_as_being_sold_by_vention ? (
            <>{supplier_shipment_delay && shipmentDelayText(supplier_shipment_delay)}</>
          ) : (
            <>
              {partner_feature_level === 'take_rate' &&
                `Supplied by ${partner_name} and subject to their terms & conditions`}
            </>
          )}
        </div>
        <div className='design-bom-item-quantity'>{quantity}</div>
      </div>
    </>
  )
}

export default DesignBomPartnerPart
