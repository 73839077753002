import React from 'react'
import { shipmentDelayText } from '../DesignBom'

export interface DeploymentBundle {
  id: number
  content_md: string
  description: string
  service_cost_usd: number
  service_price_usd: number
  shipment_delay: number
  extra_cost_usd: number
  extra_price_usd: number
  title: string
  created_at: string
  updated_at: string
}

export const DesignBomDeploymentBundle = (props: DeploymentBundle) => {
  return (
    <div className='design-bom-item design-bom-deployment-bundle'>
      <div className='design-bom-item-description'>
        <div className='design-bom-item-image'>
          <i className='icon-launch'></i>
        </div>
        <div>
          <div className='design-bom-item-link'>Ready to Operate Support Bundle</div>
          <div className='design-bom-deployment-bundle-title'>{props.title}</div>
        </div>
      </div>
      <div className='design-bom-item-status'>
        <div>{shipmentDelayText(props.shipment_delay)}</div>
      </div>
      <div className='design-bom-item-quantity'>1</div>
    </div>
  )
}

