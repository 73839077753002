type SystemOfMeasure = 'metric' | 'imperial'
interface Dimensions {
  readonly height?: number | string
  readonly width?: number | string
  readonly length?: number | string
}

interface Options {
  readonly repeatingUnitType?: boolean
  readonly includeIndicators?: boolean
  readonly unitType?: string
}

/**
 * Convert between metric and imperial
 * @param val
 * @param currentUnit
 */
export function convertWeightUnit(val: number, currentUnit: SystemOfMeasure): string {
  if (currentUnit === 'metric') return (val * 2.20462262).toFixed(30)
  else return (val / 2.20462262).toFixed(30)
}

/**
 * Convert between metric and imperial
 * @param val
 * @param currentUnit
 */
export function convertDistanceUnit(val: number, currentUnit: SystemOfMeasure): string {
  if (currentUnit === 'metric') return (val * 0.03937).toFixed(30)
  else return (val / 0.03937).toFixed(30)
}

/**
 * Convert between metric and imperial
 * @param val
 * @param currentUnit
 */
export function convertTemperatureUnit(val: number, currentUnit: SystemOfMeasure): string {
  if (currentUnit === 'metric') return (val * 1.8 + 32).toFixed(30)
  else return ((val - 32) / 1.8).toFixed(30)
}

export function buildDimensions(dimensions: Dimensions, options: Options = {}): string {
  const { length, width, height } = dimensions
  const { repeatingUnitType = false, includeIndicators = false, unitType = 'mm' } = options

  return [
    length ? `${length}${repeatingUnitType ? unitType : ''}${includeIndicators ? ' L' : ''}` : null,
    width ? `${width}${repeatingUnitType ? unitType : ''}${includeIndicators ? ' W' : ''}` : null,
    height ? `${height}${repeatingUnitType ? unitType : ''}${includeIndicators ? ' H' : ''}` : null,
  ]
    .filter(Boolean)
    .join(' x ')
    .concat(!repeatingUnitType ? ` ${unitType}` : '')
}
